import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import API from "../../../helpers/api";
import FNSpinner from '../../../components/FNSpinner'

const EditVechile = ({ id, close, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [licensePlate, setLicensePlate] = useState("");
  const [chassisNo, setChassisNo] = useState("");
  const [engineNo, setEngineNo] = useState("");
  const [power, setPower] = useState("");
  const [makeId, setMakeId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [yom, setYear] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [gearType, setGearType] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [countryOrgin, setCountryOrgin] = useState("");

  const [types, setTypes] = useState([]);
  const [makes, setMakes] = useState([]);

  const vehicleDetails = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/v/vehicle/${id}`);
      setLicensePlate(res.data.vehicle.licensePlate);
      setChassisNo(res.data.vehicle.chassisNo);
      setEngineNo(res.data.vehicle.engineNo);
      setPower(res.data.vehicle.power);
      setMakeId(res.data.vehicle.makeId);
      setTypeId(res.data.vehicle.typeId);
      setYear(res.data.vehicle.yom);
      setFuelType(res.data.vehicle.fuelType);
      setGearType(res.data.vehicle.gearType);
      setTotalCost(res.data.vehicle.totalCost);
      setPurchaseDate(res.data.vehicle.purchaseDate);
      setCountryOrgin(res.data.vehicle.countryOrgin);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const loadType = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/v/type`);
      console.log(res)
      setTypes(res?.data.type);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleChangeType = (selectedOption) => {
    setTypeId(selectedOption.value);
  };

  const handleChangeMake = (selectedOption) => {
    setMakeId(selectedOption.value);
  };

  const loadMake = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/v/make`);
      setMakes(res?.data.make);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      licensePlate,
      chassisNo,
      engineNo,
      power,
      makeId,
      typeId,
      yom,
      fuelType,
      gearType,
      totalCost,
      purchaseDate,
      countryOrgin
    };

    try {
      const response = await API.patch(`/v/vehicle/${id}`, data,);
      setLoading(false);
      close();
      refresh();
      toast.success("Vehicle Update Successfully !!");
    } catch {
      setLoading(false);
      toast.error("Error while Updating Vehicle");
    }
  };

  useEffect(() => {
    loadType();
    loadMake();
    vehicleDetails();
  }, []);

  return (
    <div class="card">
      <div class="card-body">
        <div className="row">
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">License Plate Number</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Number Plate"
                value={licensePlate}
                onChange={(e) => setLicensePlate(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Type</label>
              <Select

                defaultValue={typeId}
                onChange={handleChangeType}
                options={types.map(type => ({ value: type.id, label: type.name }))}
                placeholder="Select Vehicle Type"
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Make</label>
              <Select
                defaultValue={makeId}
                onChange={handleChangeMake}
                options={makes.map(make => ({ value: make.id, label: make.name }))}
                placeholder="Select Vehicle Make"
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Chassis Number</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Chassis Number"
                value={chassisNo}
                onChange={(e) => setChassisNo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Engine Number</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Engine Number"
                value={engineNo}
                onChange={(e) => setEngineNo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Fuel Type</label>
              <select class="form-select" aria-label="Select example" value={fuelType}
                onChange={(e) => setFuelType(e.target.value)}>
                <option>Select Fuel Type </option>
                <option>Diesel</option>
                <option>Petrol</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Horse Power</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Horse Power"
                value={power}
                onChange={(e) => setPower(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Year Of Manufacture</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Year of Manufacture"
                value={yom}
                onChange={(e) => setYear(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Gear Type</label>
              <select class="form-select" aria-label="Select example" value={gearType}
                onChange={(e) => setGearType(e.target.value)}>
                <option>Select Gear Type </option>
                <option>Manual</option>
                <option>Automatic</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Total Cost</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Total Cost"
                value={totalCost}
                onChange={(e) => setTotalCost(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Purchase Date</label>
              <input type="date" class="form-control" autocomplete="off" placeholder="Purchase date"
                value={purchaseDate}
                onChange={(e) => setPurchaseDate(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Country of Orgin</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Country of Origin"
                value={countryOrgin}
                onChange={(e) => setCountryOrgin(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="div mt-3">
          <button class="btn btn-primary me-2" onClick={handleSubmit}>{loading ? <FNSpinner /> : "Add New Vehicle"}</button>
          <button class="btn btn-outline-primary" onClick={close}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default EditVechile
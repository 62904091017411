import React from 'react'
import { Spinner } from "react-bootstrap";

const FNSpinner = () => {
  return (
    <Spinner animation="border" variant="light" role="status" as="span" size="sm" style={{ color: 'blue' }}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  )
}

export default FNSpinner